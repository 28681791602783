<script lang="ts" setup>
import { getDistance } from 'geolib';
import { useAuthStore } from '@/stores/auth';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation'
import { IconMapPin, IconChevronDown, IconRefresh, IconMapPinSearch, IconMapPinCheck, IconMapPinPlus, IconHandStop, IconMapPinExclamation, IconMapPinPin, IconShoppingBagExclamation } from '@tabler/icons-vue';
import SnackBar from '@/components/ui-components/alert/SnackBar.vue';
import { useStoresStore } from '@/stores/apps/store';
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance() 
import loadingImage from '/public/images/lottie_files/loading-gps.json';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const auth = useAuthStore()
const stores = useStoresStore()
const snackAlertMessage = ref<{
    message: string;
    type: 'success' | 'error' | 'info' | '';
    show: boolean;
    timeout?: number;
}>({
    message: '',
    type: '',
    show: false,
    timeout: 3000,
});

const storeIsNotSet = ref(false)
const tempStore = ref({ name: null, city: null});
const userCoordinates = ref()
const loadingProximity = ref(false);
const selectStoreMessage = ref('');
const establishments = ref(stores.stores.data);
const promptChangeStore = ref(false);
const checkedProximity = ref(false);
const nearbyStores = <any>ref(await stores.getNearbyStores)
const selectedStore = ref();
const locationErrorType = ref('');

const getUserCoordinates = computed(() => stores.getUserCoordinates );


const validateSelectClosestStore = async (from?:string|null) => {
    if (selectedStore.value && !from) return true;
    nearbyStores.value = await stores.getNearbyStores;
    const closestDistance = 500;
    // console.info("entra validateSelectClosestStore... from: ", from)
    // console.info("nearbyStores.value.length: ", nearbyStores.value.length)
    // if (stores.selectedStore.city) return;
    if (nearbyStores.value.length === 1 && nearbyStores.value[0].currentDistance <= closestDistance){
        _setSelectedStore(nearbyStores.value[0])
        snackAlertMessage.value = { show: true, type: 'success', message: 'Ubicación seleccionada'};
        storeIsNotSet.value = false;
    } else if (nearbyStores.value.filter((store:any) => store.currentDistance <= closestDistance).length === 1 && !from){
        const closestStore = nearbyStores.value.filter((store:any) => store.currentDistance <= closestDistance)[0];
        if (closestStore){
            if ((selectedStore.value && selectedStore.value.id != closestStore.id) || !selectedStore.value){
                _setSelectedStore(closestStore)
                snackAlertMessage.value = { show: true, type: 'success', message: `${t('login.welcome')} ${t('basic.to')} ${closestStore.name}`};
            } 
            storeIsNotSet.value = false;
        } else {
            storeIsNotSet.value = true;
        }
    } /* else {
        storeIsNotSet.value = true;
    }*/
    // console.log("storeIsNotSet.value:", storeIsNotSet.value)
}

const _setSelectedStore = async function (value:any) {
    if (Object.keys(value).length === 0){
        await stores.setSelectedStore(value);
        return;
    };
    await stores.setSelectedStore(value);
    await useEcomStore().deleteCart("LocationHandler");
    await useInvoiceStore().setInvoice('');
    const vuetify = proxy.$vuetify
    const activeTheme = vuetify.theme.current
    try {
        if (activeTheme.name === 'ORANGE_THEME') {
            vuetify.theme.themes.ORANGE_THEME.colors.accent = value.company_info.primary_color
        } else if (activeTheme.name === 'DARK_ORANGE_THEME') {
            vuetify.theme.themes.DARK_ORANGE_THEME.colors.accent = value.company_info.primary_color
        }
    } catch (e) {
        //
    }
    storeIsNotSet.value = false;
}

const positionHandler = async (latitude:any, longitude:any, from?:string|null) => {
    checkedProximity.value = true;
    loadingProximity.value = true;
    selectStoreMessage.value = '';


    stores.setUserCoordinates({lat: latitude, lon: longitude})

    // console.log("positionHandler ok, lat: " + latitude + ", lon: " + longitude, from);
    userCoordinates.value = `${latitude},${longitude}`
    try {
        establishments.value = await stores.refreshNearbyStores({ 
                                    lat: latitude, 
                                    lon: longitude })
    } catch (error) {
        console.error(error);
        snackAlertMessage.value = { show: true, type: 'warning', message: `Sorry, tenemos inconvenientes, intenta en unos segundos`};
    }

    const userLocation = {
        latitude: latitude,
        longitude: longitude
    };

    if (establishments.value && establishments.value.length > 0){
        selectStoreMessage.value = '';
        // console.log("into PH 1: ")
        await establishments.value.forEach(async (_store:any) => {
            const store_location = _store.location.split(',')
            const distance = await getDistance(userLocation, { 
                                                latitude: store_location[0], 
                                                longitude: store_location[1]
                                            });
            if (distance <= 1500 || auth.isSuperUser){
                // console.info("Nearby store found: " + _store)
                _store.isNearby = true;
            } else {
                _store.isNearby = false;
            }
            _store.currentDistance = distance;
        });
        // console.log("into PH 2: ")
        await stores.setNearbyStores(establishments.value);
        // console.log("into PH 3: ")
        await validateSelectClosestStore(from);
        loadingProximity.value = false;
    } else {
        // console.log("into PH 4 (else): ")
        stores.setNearbyStores([]);
        _setSelectedStore({})
        selectStoreMessage.value = t('app.messages.notNearbyStoresFound')
        snackAlertMessage.value = { show: true, type: 'error', message: selectStoreMessage.value, timeout: 5000};
        locationErrorType.value = 'near';
        auth.setToShowTutorial(1)
        loadingProximity.value = false;
    }

    if (from === 'menu'){
        storeIsNotSet.value = true;
    }
}

const errorPosition = (error = { code: 0, message: ''}) => {
    loadingProximity.value = false;
    console.error(error);
    if (error.code === 1){
        selectStoreMessage.value = `<h3>ERROR:</h3><br><b>${ t('basic.Instructions')}:</b><br><ul><li><b>iPhone:</b> ${ t('errors.gpsErrors.instructionsIphone') }</li></ul>`;
    } else {
        selectStoreMessage.value = `<h3>ERROR:</h3><br><b>[Code ${error.code}]</b><br><ul><li>${error.message ?? error.code == 2 ? 'PERMISO DENEGADO' : error.code == 3 ? 'TIMEOUT' : 'OTRO'}</li></ul>`;
    }
    locationErrorType.value = 'gps';
    selectStoreMessage.value += t('app.messages.yourGPSLocationNotWorking')
    snackAlertMessage.value = { show: true, type: 'error', message: selectStoreMessage.value, timeout: 5000};
    console.error(`Error retrieving user location: ${error.code}:${error.message}`);
}

const checkProximity = async (from?:string|null) => {
    loadingProximity.value = true;
    console.log("into checkProximity, from:", from)
    if (Capacitor.isNativePlatform()){
        try {
            const position = await Geolocation.getCurrentPosition();
            await positionHandler(position.coords.latitude, position.coords.longitude, from);
        } catch (error) {
            console.error("Error on Capacitor location:", error);
            errorPosition({code: 0, message: 'Error retrieving user location: '});
        }
        loadingProximity.value = false;
        return;
    } else if ('geolocation' in navigator && navigator.geolocation) {
        try {
            navigator.geolocation.getCurrentPosition(
                async (position) => await positionHandler(position.coords.latitude, position.coords.longitude, from),
                errorPosition,
                {
                    enableHighAccuracy: Capacitor.isNativePlatform() ? false : true,
                    timeout: 7000, // 7 segundos
                    maximumAge: 0
                });
        } catch(error) {
            console.error(`Error retrieving user location:`, error);
            errorPosition({code: 0, message: 'Error retrieving user location: '});
        }
    } else {
        errorPosition({code: 0, message: 'Geolocation is not supported by this browser.'});
    }
};

async function changeStore(answer:boolean){
    if(answer && tempStore.value.id){
        await nearbyStores.value.forEach(async (store:any) => {
            if (store.id === tempStore.value.id){
                await _setSelectedStore(store);
                // snackAlertMessage.value = { show: true, type: 'success', message: 'Ubicación actualizada'};
                // console.log(snackAlertMessage.value)
            }
        });
        promptChangeStore.value = false;
    } else {
        promptChangeStore.value = false; 
        tempStore.value = { id: null, name: null }; 
    }
     // reset tempStore for next use.  This is important to avoid memory leaks.  Otherwise, the component will keep holding onto the old reference.  This could cause unexpected behavior if the store object changes.  In this case, we are just resetting it to null.  In a real-world scenario, you would need to update the reference to the new store object.  For example, if the store object is a reactive object, you might update it using Vue's set() function.  But for simplicity, I've chosen to just reset it.  In a production application, you would want to handle this more gracefully.  For example, you could use Vue's watch to detect changes in the store object and update the reference automatically.  But again, for simplicity, I've chosen to avoid this for this example
}

onBeforeMount(async () => {
    if (getUserCoordinates.value.lon){
        await validateStoreSet(stores.getSelectedStore);
    }
    selectedStore.value = stores.getSelectedStore;
});

async function validateStoreSet(selectedStoreValue:any) {
    // console.log("selectedStoreValue: ", selectedStoreValue)
    selectedStore.value = Object.keys(selectedStoreValue).length > 0
    if (!selectedStore.value){ 
        // console.log("pasa 1")
        //storeIsNotSet.value = true;
        await positionHandler(getUserCoordinates.value.lat, getUserCoordinates.value.lon);
    } else {
        // console.log("pasa 2")
        storeIsNotSet.value = false;
        // await positionHandler(getUserCoordinates.value.lat, getUserCoordinates.value.lon);
    }
}

watch([() => stores.getSelectedStore], async ( selectedStoreValue ) => {
    // console.log("pasa por watch(getUserCoordinates)")
    if (Object.keys(selectedStoreValue).length > 0) {
        await validateStoreSet(selectedStoreValue)
    }
});

</script>
<template>
    <v-btn variant="text" @click="checkProximity('menu')" >
        <IconMapPinExclamation v-if="Object.keys(stores.getSelectedStore).length === 0" class="icon-map icon-error" stroke-width="1.8" size="28" /> 
        <IconMapPinCheck class="icon-map icon-success" v-else stroke-width="1.5" size="28" /> 
        <!-- {{ selectedStore.name ?? 'Selecciona una tienda' }} -->
        <!-- <IconChevronDown stroke-width="1.5" size="17" class="ml-1" /> -->
        <span class="text-xs">{{ $t('Store') }}</span>
        <slot>
        </slot>
    </v-btn>
    <v-dialog v-model="storeIsNotSet" max-width="400" >
        <v-card>
            <template v-slot:append>
                <v-btn icon="$close" variant="text" color="primary" @click="storeIsNotSet = false"></v-btn>
            </template>
            <template v-slot:title>
                <h3 class="text-h3 text-primary"><IconMapPin size="28" class="text-primary" style="margin-bottom: -5px" />  {{ $t('basic.where_are_you') }}</h3>
            </template>
            <template v-slot:text>
                <p class="text-center">
                    <span v-if="selectedStore">
                        {{ $t('current_location') }}<br>
                        <span class="text-h5">{{ selectedStore.name }}</span>
                    </span>
                    <span v-else>{{ $t('basic.select2')}}:</span>
                </p>
                <div v-if="nearbyStores.length > 0 && !loadingProximity && (checkedProximity || getUserCoordinates.lat)" class="d-flex" >
                    <VSelect v-model="tempStore" 
                        name="lang" 
                        density="compact" 
                        variant="outlined"
                        class="py-4 px-1"
                        persistent-hint
                        :hint="loadingProximity ? t('basic.loading') : tempStore.name ? `${tempStore.name} (${tempStore.city})` : $t('select_store_where_are_you')"
                        return-object
                        item-title="name"
                        :no-data-text="$t('location.no-stores-near-you')"
                        :items="nearbyStores"
                        >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="(!item.raw.isNearby && !auth.isSuperUser) || item.raw.id === selectedStore.id" style="color: rgb(var(--v-theme-on-surface-variant))">
                                <v-list-item-subtitle >
                                    {{ item.raw.address }}
                                    <span v-if="item.raw.currentDistance >= 0">({{ item.raw.currentDistance < 1000 ? item.raw.currentDistance+'mts' : new Intl.NumberFormat('es-CO').format((item.raw.currentDistance/1000).toFixed(1))+'km' }})</span>
                                    <IconMapPin v-if="item.raw.currentDistance <= 500" size="14" class="text-primary ml-2" />
                                </v-list-item-subtitle>
                            </v-list-item>
                        </template>
                    </VSelect>
                    <v-btn v-if="!tempStore.name" @click="checkProximity('button')" :disabled="loadingProximity" icon variant="plain" class="mt-2" >
                        <IconRefresh size="24" />
                    </v-btn>
                </div>
                <div v-else-if="loadingProximity" class="d-flex align-center w-100 text-center justify-center text-primary py-4">
                    <IconHandStop size="24" class="text-primary mr-2" /> {{$t('basic.pleaseWait')}}
                </div>
                <v-alert v-if="selectStoreMessage !== ''" type="error" variant="tonal" class="mt-3 pa-1 text-subtitle-1">
                    <p v-html="selectStoreMessage"></p>
                </v-alert>
                <v-alert v-if="useEcomStore().getTotalQty > 0" color="error" icon="mdi-basket-remove" variant="tonal" class="mt-3 pa-4 text-subtitle-1">
                    <span>{{ $t('basic.you_have_items_in_cart') }}</span>
                </v-alert>
            </template>
            <template v-slot:actions>
                <div class="d-flex align-center w-100 text-center justify-center">
                    <v-btn v-if="tempStore.name" @click="changeStore(true)" color="primary" variant="tonal" rounded >
                        <IconMapPinPlus stroke-width="1.8" size="18" class="mr-1" />  {{ $t('basic.select')}}
                    </v-btn>
                </div>
            </template>
        </v-card>
    </v-dialog>
    <v-dialog v-model="promptChangeStore" max-width="400" persistent >
        <v-card v-if="tempStore.id" >
            <template v-slot:append>
                <v-btn icon="$close" variant="text" color="primary" @click="promptChangeStore = false"></v-btn>
            </template>
            <template v-slot:title>
                <IconMapPin size="24" class="text-primary" /> <span class="text-h3 text-primary">{{ $t('location.store-change') }}</span>
            </template>
            <template v-slot:text>
                <div class="pa-2">
                    {{ $t('location.are-you-sure-change-store') }}<br><br>
                    <p class="text-center">
                        <b>{{ selectedStore && selectedStore.name }}</b> <br />
                        {{ $t('basic.to')}}<br />
                        <b>{{ tempStore.name }}</b><br />
                    </p>
                    <br />
                    <p class="h6 text-center">
                        {{ $t('location.alert-lose-products') }}<br/>
                        {{ $t('location.remember-presence-at-store') }}
                    </p>
                </div>
            </template>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="changeStore(false);">{{ $t('actions.cancel') }}</v-btn>
                <v-btn color="error" @click="changeStore(true)">{{ $t('basic.change') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <SnackBar :alert="snackAlertMessage" />
    <v-overlay v-model="loadingProximity" ref="preloader" class="fixed top-0 left-0 right-0 bottom-0 d-flex flex-column text-center h-screen align-center justify-center bg-white" :opacity="0.6"
        style="position: absolute; z-index: 9999; opacity: 1; transition: opacity 0.5s ease; background-color: rgba(var(--v-theme-containerBg), 0.6) !important; backdrop-filter: blur(7px) !important;"
        >
        <div class="bg-grey100 rounded rounded-circle pa-1" style="width: 200px; height: 200px;">
            <Vue3Lottie :animationData="loadingImage" width="100%" class="d-block mx-0" />
            <h4 class="text-h4 font-weight-normal pt-8">{{ $t('basic.pleaseWait')}}</h4>
        </div>
    </v-overlay>
</template>

<style lang="scss" scope>
.icon-map {
    stroke-width: 2px;
    &.icon-success path:nth-of-type(n+3) {
        color: rgb(var(--v-theme-success));
    }
    &.icon-error path:nth-of-type(n+3) {
        color: rgb(var(--v-theme-error));
    }
}
</style>